$(function () {
    let searchInput = $('#search-input')
    let elResults = $('.search-results')
    let minLength = 3

    searchInput.on('change keyup', function () {
        var _el = $(this),
            _term = _el.val(),
            _chars = _term.length;

        if (_chars >= minLength) {
            $.get('/index.php?route=product/search/suggestSearch', {
                term: _term
            }, function (data) {
                elResults.html('');

                if (! $.isEmptyObject(data)) {
                    $.each(data, function (key, item) {
                        if (! $.isEmptyObject(item)) {
                            elResults.append(
                                `<a href="` + item['href'] + `" class="result">` +
                                    `<div class="result__image">` +
                                        `<img src="` + item['thumb'] + `" />` +
                                    `</div>` +
                                    `<div class="result__text">` +
                                        `<h3 class="result-name">` + item['name'] + `</h3>` +
                                        `<h3 class="result-category">` + item['category'] + `</h3>` +
                                    `</div>` +
                                `</a>`
                            );
                        }
                    });
                }
            });
        } else {
            elResults.html('');
        }
    });
})
